export const imageDetails = [
  {
    i: 0,
    desc: "Brainstorming",
  },
  {
    i: 1,
    desc: "Brainstorming",
  },
  {
    i: 2,
    desc: "'What if' ideations",
  },
  {
    i: 3,
    desc: "The book that saves you tampon tax",
  },
  {
    i: 4,
    desc: "Systems that make us angry",
  },
  {
    i: 5,
    desc: "The process to donate the unclaimed drink",
  },
  {
    i: 6,
    desc: "Print of the QR code as digital device like phones are not accessible by homeless.",
  },
  {
    i: 7,
    desc: "Combining the idea with the shelter homes.",
  },
  {
    i: 8,
    desc: "Formally printed",
  },
  {
    i: 9,
    desc: "Roughly hand-written.",
  },
  {
    i: 10,
    desc: "Sharing their feedback through drawings",
  },
  {
    i: 11,
    desc: "SUBSCRIPTION SHARING HONOR SYSTEM",
  },
  {
    i: 12,
    desc: "SUBSCRIPTION SHARING HONOR SYSTEM",
  },
  {
    i: 13,
    desc: "STUDENT DISCOUNT ECONOMY",
  },
  {
    i: 14,
    desc: "STUDENT DISCOUNT ECONOMY",
  },
  {
    i: 15,
    desc: "GEOLOCATION-BASED STUDENT DISCOUNT",
  },
  {
    i: 16,
    desc: "Business Origami",
  },
  {
    i: 17,
    desc: "Business Origami",
  },
  {
    i: 18,
    desc: "Making stencils of a parent QR code, which collects & holds all kind of coupons available around that location. We added varied hearts around the QR to spark informality and relatability for users.",
  },
  {
    i: 19,
    desc: "Making stencils of a parent QR code, which collects & holds all kind of coupons available around that location. We added varied hearts around the QR to spark informality and relatability for users.",
  },
  {
    i: 20,
    desc: "We spray painted at a legal graffiti tunnel in Leake Street, London as its busy with passer-byes and closer to the chosen outlets. Red color chalk spray was used as it's easy to spot and erase later.",
  },
  {
    i: 21,
    desc: "We spray painted at a legal graffiti tunnel in Leake Street, London as its busy with passer-byes and closer to the chosen outlets. Red color chalk spray was used as it's easy to spot and erase later.",
  },
  {
    i: 22,
    desc: "We spray painted at a legal graffiti tunnel in Leake Street, London as its busy with passer-byes and closer to the chosen outlets. Red color chalk spray was used as it's easy to spot and erase later.",
  },
  {
    i: 23,
    desc: "People can scan the QR with mobile phones, which allows them to use the available code.",
  },
  {
    i: 24,
    desc: "People can scan the QR with mobile phones, which allows them to use the available code.",
  },
  {
    i: 25,
    desc: "People can scan the QR with mobile phones, which allows them to use the available code.",
  },
  {
    i: 26,
    desc: "Sharing on social media",
  },
  {
    i: 27,
    desc: "Response once people used the QR for real.",
  },
];
