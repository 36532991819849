export const imageDetails = [
  {
    i: 0,
    desc: "Brainstorming on Miro board",
  },
  {
    i: 1,
    desc: "Brainstorming on Miro board",
  },
  {
    i: 2,
    desc: "Ancient Romans used Urine as Mouth-wash.",
  },
  {
    i: 3,
    desc: "Human sweat induced in perfume.",
  },
  {
    i: 4,
    desc: "Pure urine used during drug test by drug users.",
  },
  {
    i: 5,
    desc: "Websites to sell/buy breast milk.",
  },
  {
    i: 6,
    desc: "Business origami of user journey",
  },
  {
    i: 7,
    desc: "Business origami of user journey",
  },
  {
    i: 8,
    desc: "Business origami of user journey",
  },
  {
    i: 9,
    desc: "Comparison chart",
  },
  {
    i: 10,
    desc: "During the 'Pee Experiment', Walking around with pee, exchanging pee",
  },
  {
    i: 11,
    desc: "During the 'Pee Experiment', Walking around with pee, exchanging pee",
  },
  {
    i: 12,
    desc: "During the 'Pee Experiment', Walking around with pee, exchanging pee",
  },
  {
    i: 13,
    desc: "Wee pays Van, enacting the system",
  },
  {
    i: 14,
    desc: "Wee pays Van, enacting the system",
  },
  {
    i: 15,
    desc: "Sketch of container",
  },
  {
    i: 16,
    desc: "A situation that recently happened at my home.",
  },
  {
    i: 17,
    desc: "Shopping at Amazon.",
  },
  {
    i: 18,
    desc: "Ideating with objects",
  },
  {
    i: 19,
    desc: "Ideating with objects",
  },
  {
    i: 20,
    desc: "Apple juice",
  },
  {
    i: 21,
    desc: "Human urine",
  },
  {
    i: 22,
    desc: "Around Southwark police station",
  },
  {
    i: 23,
    desc: "Around Southwark police station",
  },
  {
    i: 24,
    desc: "Around Southwark police station",
  },
  {
    i: 25,
    desc: "Around Southwark police station",
  },
  {
    i: 26,
    desc: "Elements considered in our initial design concept",
  },
  {
    i: 27,
    desc: "Trust journey in exchange of urine through Gumtree from Buyer's journey map",
  },
  {
    i: 28,
    desc: "Trust journey in exchange of urine through Gumtree from Buyer's journey map",
  },
  {
    i: 29,
    desc: "Finding the suitable user",
  },
  {
    i: 30,
    desc: "Checking the purity of urine",
  },
  {
    i: 31,
    desc: "Safe meeting spots",
  },
  {
    i: 32,
    desc: "Container to collect and store urine",
  },
  {
    i: 33,
    desc: "Checking if it's real urine",
  },
  {
    i: 34,
    desc: "Safe payment system",
  },
  {
    i: 35,
    desc: "Branding for Yellow Juice",
  },
  {
    i: 36,
    desc: "Branding for Yellow Juice",
  },
  {
    i: 37,
    desc: "Branding across recommended place for exchange",
  },
  {
    i: 38,
    desc: "Branding across recommended place for exchange",
  },
  {
    i: 39,
    desc: "Branding across recommended place for exchange",
  },
  {
    i: 40,
    desc: "Branding across recommended place for exchange",
  },
  {
    i: 41,
    desc: "The Yellow Juice container and it's size variations for various users",
  },
  {
    i: 42,
    desc: "The Yellow Juice container and it's size variations for various users",
  },
  {
    i: 43,
    desc: "The Yellow Juice container and it's size variations for various users",
  },
  {
    i: 44,
    desc: "The Yellow Juice container and it's size variations for various users",
  },
  {
    i: 45,
    desc: "The Yellow Juice container and it's size variations for various users",
  },
  {
    i: 46,
    desc: "Memes on Yellow juice",
  },
  {
    i: 47,
    desc: "Memes on Yellow juice",
  },
  {
    i: 48,
    desc: "Memes on Yellow juice",
  },
  {
    i: 49,
    desc: "Memes on Yellow juice",
  },
];
