export const imageDetails = [
  {
    i: 0,
    desc: "Brainstorming on Miro board",
  },
  {
    i: 1,
    desc: "Brainstorming on Miro board",
  },
  {
    i: 2,
    desc: "At Ron Mueck’s exhibition",
  },
  {
    i: 3,
    desc: "At Ron Mueck’s exhibition",
  },
  {
    i: 4,
    desc: "At Ron Mueck’s exhibition",
  },
  {
    i: 5,
    desc: "At Ron Mueck’s exhibition",
  },
  {
    i: 6,
    desc: "Speed dating, Building the suits",
  },
  {
    i: 7,
    desc: "Speed dating, Building the suits",
  },
  {
    i: 8,
    desc: "Speed dating, Building the suits",
  },
  {
    i: 9,
    desc: "Speed dating, Building the suits",
  },
  {
    i: 10,
    desc: "Volunteers interacting with the mannequin",
  },
  {
    i: 11,
    desc: "Volunteers interacting with the mannequin",
  },
  {
    i: 12,
    desc: "Volunteers interacting with the mannequin",
  },
  {
    i: 13,
    desc: "AEIOU",
  },
  {
    i: 14,
    desc: "Costums by Oskar Schlemmer (Bauhaus) for Ballet triadique",
  },
  {
    i: 15,
    desc: "Prototyping costumes for extended skin",
  },
  {
    i: 16,
    desc: "Prototyping costumes for extended skin",
  },
  {
    i: 17,
    desc: "Prototyping costumes for extended skin",
  },
  {
    i: 18,
    desc: "Prototyping costumes for extended skin",
  },
  {
    i: 19,
    desc: "Prototyping costumes for extended skin",
  },
  {
    i: 20,
    desc: "Final Performance",
  },
  {
    i: 21,
    desc: "Final Performance",
  },
  {
    i: 22,
    desc: "Final Performance",
  },
  {
    i: 23,
    desc: "Final Performance",
  },
  {
    i: 24,
    desc: "Final Performance",
  },
  {
    i: 25,
    desc: "Final Performance",
  },
];
