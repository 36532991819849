export const imageDetails = [
  {
    i: 0,
    desc: "The flooding waterfalls",
  },
  {
    i: 1,
    desc: "The flooding waterfalls",
  },
  {
    i: 2,
    desc: " River- Happy (freely moving)",
  },
  {
    i: 3,
    desc: "Water falls- Anger (forceful fall)",
  },
  {
    i: 4,
    desc: "Ocean- Fear (to and fro of waves)",
  },
  {
    i: 5,
    desc: "Lake- Sad (stillness)",
  },
  {
    i: 6,
    desc: "Rain- Surprise (Sudden pour)",
  },
  {
    i: 7,
    desc: "The filter sheet manually rotated in front of the light source for change in emotions",
  },
  {
    i: 8,
    desc: "Color psychology based on human emotions",
  },
  {
    i: 9,
    desc: "River",
  },
  {
    i: 10,
    desc: "Water Falls",
  },
  {
    i: 11,
    desc: "Ocean",
  },
  {
    i: 12,
    desc: "Lake",
  },
  {
    i: 13,
    desc: "Rain",
  },
  {
    i: 14,
    desc: "Museum",
  },
  {
    i: 15,
    desc: "Museum",
  },
];
