export const imageDetails = [
  {
    i: 0,
    desc: "Images during body storming",
  },
  {
    i: 1,
    desc: "Images during body storming",
  },
  {
    i: 2,
    desc: "Images during body storming",
  },
  {
    i: 3,
    desc: "Images during body storming",
  },
  {
    i: 4,
    desc: "Images during body storming",
  },
  {
    i: 5,
    desc: "Conceptualisation",
  },
  {
    i: 6,
    desc: "Making costumes that enhances expressing emotions",
  },
  {
    i: 7,
    desc: "Making costumes that enhances expressing emotions",
  },
  {
    i: 8,
    desc: "Making costumes that enhances expressing emotions",
  },
  {
    i: 9,
    desc: "Making costumes that enhances expressing emotions",
  },
  {
    i: 10,
    desc: "The Outcome",
  },
  {
    i: 11,
    desc: "The Outcome",
  },
  {
    i: 12,
    desc: "The Outcome",
  },
  {
    i: 13,
    desc: "The Outcome",
  },
  {
    i: 14,
    desc: "The Outcome",
  },
  {
    i: 15,
    desc: "The Outcome",
  },
  {
    i: 16,
    desc: "The Outcome",
  },
  {
    i: 17,
    desc: "The Outcome",
  },
  {
    i: 18,
    desc: "The Outcome",
  },
  {
    i: 19,
    desc: "The Outcome",
  },
];
