export const MainMenuText = {
  menuOne: {
    text: "Design + Performance",
  },
  menuTwo: {
    text: "User experience design",
  },
  menuThree: {
    text: "My journey/ CV",
  },
  menuFour: {
    text: "Let's connect",
  },
};
